<template lang="pug">
  span
    confirmation-dialog(
      v-model="showing"
      @okBtnClicked="$emit('submit')"
      v-if="editable"
      width="420px"
      title='Edit journey'
      descr='Are you sure you want to edit the journey? It may affect the students'
    )
    app-button(color="primary" @click.native="submit") {{ btnText }}
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import CourseJourneyValidator from "../../core/validation/courseJourneyValidator.js"

export default {
  mixins: [showingMixin],
  props: {
    editable: Boolean,
    candidatesCount: Number,
    btnText: String,
    formErrors: Object,
    formData: Object
  },

  methods: {
    submit() {
      this.formErrors.resetFields()
      if (!this.validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      if (this.editable && this.candidatesCount > 0) {
        this.updateShowing(true)
        return
      }
      this.$emit('submit')

    },

    validate() {
      let valid = new CourseJourneyValidator(this.formData, this.formErrors).isValid()
      if (!valid) {
        if (this.formErrors.hasError('journey_days_count')) this.$notify({text: 'Please, select course', type: 'error'})
        else this.$notify({text: 'Invalid fields', type: 'error'})
      }
      return valid
    },
  },

  components: {
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>
